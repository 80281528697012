import React from "react";
import { Link } from "gatsby";

/*** COMPONENTS ***/
import Main from "../components/main";
import SEO from "../components/seo";
import "../styles/illustrations.css";
import Jellyfish from "../gatsby_images/image-previewjellyfish";
import Wet from "../gatsby_images/image-previewwet";
import Burst from "../gatsby_images/image-previewburst";
import Bloom from "../gatsby_images/image-previewbloom";
import Skull from "../gatsby_images/image-previewskull";

/*** CONSTANTS ***/
import * as ROUTES from "../constants/routes";

const illustrationsPage = () => (
  <Main>
    <SEO title="Illustrations" />
    <div class="cards-illustrations">
      <div class="card-illustrations" style={{ "--animation-order": 3 }}>
        <Jellyfish />
        <Link class="illustrations-link" to={ROUTES.JELLYFISH} />
      </div>
      <div class="card-illustrations" style={{ "--animation-order": 1 }}>
        <Wet />
        <Link class="illustrations-link" to={ROUTES.WET} />
      </div>
      <div class="card-illustrations" style={{ "--animation-order": 5 }}>
        <Burst />
        <Link class="illustrations-link" to={ROUTES.BURST} />
      </div>
      <div class="card-illustrations" style={{ "--animation-order": 4 }}>
        <Bloom />
        <Link class="illustrations-link" to={ROUTES.BLOOM} />
      </div>
      <div class="card-illustrations" style={{ "--animation-order": 2 }}>
        <Skull />
        <Link class="illustrations-link" to={ROUTES.SKULL} />
      </div>
    </div>
  </Main>
);

export default illustrationsPage;
